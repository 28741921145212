import React, {useCallback, useState} from "react";
import {useForm} from "react-hook-form";
// import {toast} from 'react-toastify';

// const BASE_URL = process.env.REACT_APP_API_END_POINT || 'https://zeodel.herokuapp.com';
// const CONTACT_URL = `${BASE_URL}/contact`;

const ContactForm = () => {
    const {register, handleSubmit, reset} = useForm();
    const [submitting, setSubmitting] = useState(false);
    const onSubmit = useCallback(async (data) => {
        console.log(data);
        setSubmitting(false);
        reset();
        // @TODO - Update Contact Form
        // setSubmitting(true);
        // const headers = new Headers();
        // headers.append("Content-Type", "application/json");
        //
        // const requestOptions = {
        //     method: 'POST',
        //     headers: headers,
        //     body: JSON.stringify(data),
        //     redirect: 'follow'
        // };
        //
        // fetch(CONTACT_URL, requestOptions)
        //     .then(response => response.text())
        //     .then(() => {
        //         toast.success('Message Sent! We will get back to you soon.', {
        //             position: "top-right",
        //             autoClose: 5000,
        //             hideProgressBar: true,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //         });
        //         reset();
        //     })
        //     .catch(() => {
        //         toast.error('Error Sending Message! Please Try Again.', {
        //             position: "top-right",
        //             autoClose: 5000,
        //             hideProgressBar: true,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //         });
        //     })
        //     .finally(() => {
        //         setSubmitting(false);
        //     });
    }, [reset]);


    return (
        <div className="contact-form--1">
            <div className="container">
                <div className="row row--35 align-items-start">
                    <div className="col-lg-6 order-2 order-lg-1">
                        <div className="section-title text-left mb--50">
                            <h2 className="title">Contact Us.</h2>
                            <p className="description">We would love to hear from you.</p>
                        </div>
                        <div className="form-wrapper">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <label htmlFor="item01">
                                    <input
                                        type="text"
                                        name="name"
                                        id="item01"
                                        {...register("name", {required: true})}
                                        placeholder="Your Name *"
                                        required={true}
                                    />
                                </label>

                                <label htmlFor="item02">
                                    <input
                                        type="text"
                                        name="email"
                                        id="item02"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                        {...register("email", {required: true})}
                                        placeholder="Your email *"
                                        required={true}
                                    />
                                </label>

                                <label htmlFor="item03">
                                    <input
                                        type="text"
                                        name="subject"
                                        id="item03"
                                        {...register("subject", {required: true})}
                                        placeholder="Write a Subject"
                                        required={true}
                                    />
                                </label>
                                <label htmlFor="item04">
                                        <textarea
                                            id="item04"
                                            name="message"
                                            {...register("body", {required: true})}
                                            placeholder="Your Message"
                                            required={true}
                                        />
                                </label>
                                <button
                                    className={"rn-button-style--2 btn-solid"}
                                    type="submit"
                                    value="submit"
                                    name="submit"
                                    id="mc-embedded-subscribe"
                                >
                                    {!submitting ? 'Submit' : <div className='btn-loader'/>}
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                        <div className="thumbnail mb_md--30 mb_sm--30">
                            <img src="/assets/images/about/about-6.jpg" alt="Zeodel"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm;
