import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';

import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as serviceWorker from './serviceWorker';
import {LazyLoader} from './component/common/LazyLoader';
import App from "./component/common/App";
import Contact from "./elements/Contact";
import About from "./elements/About";
import Error404 from "./elements/error404";

const Root = () => {
    return (
        <>
            <ToastContainer/>
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`}
                           component={(props) => <LazyLoader {...props} Component={App}/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`}
                           component={(props) => <LazyLoader {...props} Component={Contact}/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/about`}
                           component={(props) => <LazyLoader {...props} Component={About}/>}/>
                    <Route path={`${process.env.PUBLIC_URL}/404`}
                           component={(props) => <LazyLoader {...props} Component={Error404}/>}/>
                    <Route component={(props) => <LazyLoader {...props} Component={Error404}/>}/>
                </Switch>
            </BrowserRouter>
        </>
    )
};

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();
