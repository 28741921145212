import React from "react";
import PageHelmet from "../component/common/Helmet";
// import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
// import GoogleMapReact from 'google-map-react';
import ContactForm from "./contact/ContactForm";
// import BrandTwo from "../elements/BrandTwo";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Breadcrumb from "./common/Breadcrumb";

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Contact = () => {
    return (
        <React.Fragment>
            <PageHelmet pageTitle='Contact'/>

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Contact Us'}/>
            {/* End Breadcrump Area */}


            {/*/!* Start Contact Top Area  *!/*/}
            {/*<div className="rn-contact-top-area ptb--120 bg_color--5">*/}
            {/*    <div className="container">*/}
            {/*       */}
            {/*        <div className="row">*/}
            {/*            /!* Start Single Address  *!/*/}
            {/*            <div className="col-lg-4 col-md-6 col-sm-6 col-12">*/}
            {/*                <div className="rn-address">*/}
            {/*                    <div className="icon">*/}
            {/*                        <FiHeadphones />*/}
            {/*                    </div>*/}
            {/*                    <div className="inner">*/}
            {/*                        <h4 className="title">Contact With Phone Number</h4>*/}
            {/*                        <p><a href="tel:+057 254 365 456">+057 254 365 456</a></p>*/}
            {/*                        <p><a href="tel:+856 325 652 984">+856 325 652 984</a></p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            /!* End Single Address  *!/*/}

            {/*            /!* Start Single Address  *!/*/}
            {/*            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">*/}
            {/*                <div className="rn-address">*/}
            {/*                    <div className="icon">*/}
            {/*                        <FiMail />*/}
            {/*                    </div>*/}
            {/*                    <div className="inner">*/}
            {/*                        <h4 className="title">Email Address</h4>*/}
            {/*                        <p><a href="mailto:admin@gmail.com">admin@gmail.com</a></p>*/}
            {/*                        <p><a href="mailto:example@gmail.com">example@gmail.com</a></p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            /!* End Single Address  *!/*/}

            {/*            /!* Start Single Address  *!/*/}
            {/*            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">*/}
            {/*                <div className="rn-address">*/}
            {/*                    <div className="icon">*/}
            {/*                        <FiMapPin />*/}
            {/*                    </div>*/}
            {/*                    <div className="inner">*/}
            {/*                        <h4 className="title">Location</h4>*/}
            {/*                        <p>5678 Bangla Main Road, cities 580 <br /> GBnagla, example 54786</p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            /!* End Single Address  *!/*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*/!* End Contact Top Area  *!/*/}

            {/* Start Contact Page Area  */}
            <div className="rn-contact-page ptb--120 bg_color--1">
                <ContactForm/>
            </div>
            {/* End Contact Page Area  */}

            {/*/!* Start Contact Map  *!/*/}
            {/*<div className="rn-contact-map-area position-relative">*/}
            {/*    <div style={{ height: '650px', width: '100%' }}>*/}
            {/*        <GoogleMapReact*/}
            {/*        defaultCenter={this.props.center}*/}
            {/*        defaultZoom={this.props.zoom}*/}
            {/*        >*/}
            {/*        <AnyReactComponent*/}
            {/*            lat={59.955413}*/}
            {/*            lng={30.337844}*/}
            {/*            text="My Marker"*/}
            {/*        />*/}
            {/*        </GoogleMapReact>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*/!* End Contact Map  *!/*/}

            {/*/!* Start Brand Area *!/*/}
            {/*<div className="rn-brand-area brand-separation bg_color--5 ptb--120">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-lg-12">*/}
            {/*                <BrandTwo />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*/!* End Brand Area *!/*/}

            <Footer/>

        </React.Fragment>
    )
};

Contact.defaultProps = {
    center: {
        lat: 59.95,
        lng: 30.33
    },
    zoom: 11
};

export default Contact
