import React from "react";
import {Helmet} from 'react-helmet'

const PageHelmet = ({pageTitle = 'Not Found'}) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>{pageTitle} | Zeodel Technologies Private Limited </title>
                <meta name="description" content="Zeodel Technologies Private Limited"/>
            </Helmet>
        </React.Fragment>
    )
};

export default PageHelmet;
