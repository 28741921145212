import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";

import SliderOne from "../slider/SliderOne";
import ServiceTwo from "../../elements/service/ServiceTwo";
// import CounterOne from "../elements/counters/CounterOne";
// import Testimonial from "../elements/Testimonial";
import About from "../HomeLayout/homeOne/About";
// import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
// import BlogContent from "../elements/blog/BlogContent";
// import BrandTwo from "../elements/BrandTwo";
import Helmet from "./Helmet";

const App = () => {
    return (
        <div className="active-dark">
            <Helmet pageTitle="Home"/>
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>

            {/* Start Slider Area   */}
            <div className="slider-wrapper">
                <SliderOne/>
            </div>
            {/* End Slider Area   */}

            {/* Start About Area */}
            <div className="about-area about-position-top pb--120 bg_color--1">
                <About/>
            </div>
            {/* End About Area */}

            {/* Start Service Area  */}
            <div className="service-area ptb--80  bg_image bg_image--3">
                <div className="container">
                    <ServiceTwo/>
                </div>
            </div>
            {/* End Service Area  */}

            {/*/!* Start Portfolio Area *!/*/}
            {/*<div className="portfolio-area ptb--120 bg_color--1">*/}
            {/*    <div className="portfolio-sacousel-inner mb--55">*/}
            {/*        <Portfolio />*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*/!* End Portfolio Area *!/*/}

            {/*/!* Start CounterUp Area *!/*/}
            {/*<div className="rn-counterup-area pt--25 pb--110 bg_color--1">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-lg-12">*/}
            {/*                <div className="section-title text-center">*/}
            {/*                    <h3 className="fontWeight500">Our Fun Facts</h3>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <CounterOne />*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*/!* End CounterUp Area *!/*/}
            <Footer/>

        </div>
    )
}

export default App;
