import React, {useCallback, useState} from "react";
import {Link} from 'react-router-dom';
import {FiX, FiMenu} from "react-icons/fi";
import classnames from 'classnames';
import style from './style.module.css';

const headerLogo = require('../../assets/images/white_logo_transparent_background.svg');

const Header = (props) => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const openMenuTrigger = useCallback(() => {
        setMenuOpen(true);
    }, [setMenuOpen]);

    const closeMenuTrigger = useCallback(() => {
        setMenuOpen(false);
    }, [setMenuOpen]);


    const {color = 'default-color'} = props;

    return (
        <header className={`header-area formobile-menu header--transparent ${color}`}>
            <div className={classnames('header-wrapper', {'menu-open': isMenuOpen})}>
                <div className="header-left">
                    <div className="logo">
                        <a href="/">
                            <img className={style.headerLogo} src={headerLogo} alt={'logo'}/>
                        </a>
                    </div>
                </div>
                <div className="header-right">
                    <nav className="mainmenunav d-lg-block">
                        <ul className="mainmenu">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </nav>
                    {/* Start Humberger Menu  */}
                    <div className="humberger-menu d-block d-lg-none pl--20">
                        <span onClick={openMenuTrigger} className="menutrigger text-white"><FiMenu/></span>
                    </div>
                    {/* End Humberger Menu  */}
                    <div className="close-menu d-block d-lg-none">
                        <span onClick={closeMenuTrigger} className="closeTrigger"><FiX/></span>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header;
